import React, { useEffect } from 'react';
import { SalesCallCalendar } from './components/appointment-booking/sales-call-calendar';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { scopes } from './authConfig';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import { Skeleton } from 'antd';
import { Conflicts } from './components/conflicts/conflicts';
import { BookingPage } from './components/booking-page/booking-page';
import { useHeaderState } from './store/header-state';
import { CaseListPage } from './components/case-list-page/case-list-page';
import { ScriptEditorPage } from './components/script-editor/script-editor';
import { OpportunityBulkUploadPage } from './components/case-bulk-upload-page/case-bulk-upload-page';
import { AdminPage } from './components/admin-page/admin-page';
import { HasRole, Role } from './utils/auth-utils';
import { MantineProvider } from '@mantine/core';
import { InboundCenter } from './components/inbound-center/inbound-center';
import ReportingPage from './components/reporting/reporting-page';

const App: React.FC = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  let isAdmin,
    isManager,
    isInboundAgent,
    isReportingAgent = false;

  //NOTE: We need to keep this header state here so that when the language refreshes, it refreshes in all the components
  const [lang, setLang] = useHeaderState((state) => [state.language, state.setLanguage]);

  // Uses the root url as the redirect url for MSAL, so we only have to configure one redirectUri in Microsoft Entra.
  const redirectUri = window.location.origin;

  useMsalAuthentication(InteractionType.Redirect, {
    // Sends the full url being browsed in the state login request parameter.
    // After the login is done, MSAL will return the state and we'll use to redirect the browser to the original url requested by the user.
    state: window.location.toString(),
    redirectUri,
    scopes: scopes.salesAppointmentApi,
  });

  useEffect(() => {
    instance.handleRedirectPromise().then((response) => {
      // After the login is done MSAL returns whatever we sent in the "state" parameter on the login request.
      // Since we sent the original url there, parse it and tell react to navigate the original route that was requested.
      if (response?.state) {
        const redirectTo = response.state.replaceAll(redirectUri, '');
        navigate(redirectTo);
      }
    });
  }, [instance]);

  if (isAuthenticated) {
    const userAccounts = instance.getAllAccounts();
    if (userAccounts && userAccounts.length > 0) {
      instance.setActiveAccount(userAccounts[0]);
      isReportingAgent = HasRole(userAccounts, Role.LiveReporter);
      isAdmin = HasRole(userAccounts, Role.BookingAdmin);
      isManager = HasRole(userAccounts, Role.BookingManager);
      isInboundAgent = HasRole(userAccounts, Role.InboundAgent);
    }
  }
  // Get the query parameters from the url for the standalone calendar page.
  const [searchParams] = useSearchParams();
  const opportunityId = searchParams.get('opportunityId');

  return (
    <>
      <UnauthenticatedTemplate>
        <div className="container">
          <Skeleton paragraph={{ rows: 10 }} />
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <MantineProvider>
          <Routes>
            <Route path="/" element={<SalesCallCalendar opportunityId={opportunityId!} displayHeader={true} onBooking={() => 'void'} sourceSystemHeader="standalone" />} />
            <Route path="/booking" element={<BookingPage />} />

            {(isAdmin || isReportingAgent || isManager) && <Route path="/reporting" element={<ReportingPage />} />}
            {(isAdmin || isInboundAgent) && <Route path="/inbound" element={<InboundCenter />} />}
            {(isAdmin || isManager) && <Route path="/conflicts" element={<Conflicts />} />}
            {(isAdmin || isManager) && <Route path="/call-list" element={<CaseListPage />} />}
            {isAdmin && <Route path="/scripts" element={<ScriptEditorPage />} />}
            {isAdmin && <Route path="/bulk-import-opportunities" element={<OpportunityBulkUploadPage />} />}
            {isAdmin && <Route path="/admin" element={<AdminPage />} />}
          </Routes>
        </MantineProvider>
      </AuthenticatedTemplate>
    </>
  );
};

export default App;
