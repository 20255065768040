import { Dayjs } from 'dayjs';

export interface OpportunitySearchResult {
  customerSalutation: string;
  customerFirstName: string;
  customerLastName: string;
  phoneNumber: string;
  email: string;

  globalCustomerId: string;
  opportunityId: string;
  bookingStatus: string;
  planningStatus: string;
  fullAddress: string;
  createdDate: Date;
  caseId: string;
  doNotCallBefore: Dayjs | null;

  externalSlotId: string | null;
  appointmentStartDate: Dayjs | null;
  appointmentStatus: string | null;
  salesFirstName: string | null;
  salesLastName: string | null;
  externalAppointmentId: string | undefined;
  cancellationReason: string | null;
  internalComments: string | null;

  assignedAgent: string | null;
  rescheduling: boolean | null;

  salesChannel: SalesChannel | null;
}

export interface OpportunityAssignDto {
  opportunityId: string;
  caseId: string;
}

export enum LeadTier {
  None = 'None',
  Gold = 'Gold',
  Silver = 'Silver',
  Bronze = 'Bronze',
  Wood = 'Wood',
  ThirdParty = 'ThirdParty',
  Copper = 'Copper',
}

export interface Calendar {
  lead: Lead;
  slots: CalendarSlot[];
}

export interface Lead {
  gcid: string;
  opportunityId: string;
  salutation: string;
  firstName: string;
  lastName: string;
  tier: LeadTier;
  referredById: string | null;
  referredByName: string | null;
  products: string;
}

export interface CalendarSlot {
  startDate: Dayjs;
  dailyAvailableCount: number;
  salesChannel: SalesChannel;
}

export interface Slot {
  id: number;
  startDate: Dayjs;
  endDate: Dayjs;
  salesManagerName: string;
  externalType: string;
  externalStatus: string;
  meetingUrl: string;
  metaBookingInfo: string;
  externalId: string;
}

export interface SalesManagerAvailability {
  id: number;
  name: string;
  dailyBookCount: number;
  salesLeague: number;
}

export enum AppointmentType {
  None = 0,
  Slot = 1,
  SalesCall1 = 2,
  SalesCall2 = 3,
}

export interface Result<T> {
  data: T;
  errors: string[] | null;
  isSuccess: boolean;
}

export interface SlotConflict {
  slotId: number;
  slotDate: string;
  conflictingSlotId: number;
  conflictingSlotSubject: string;
  salesManagerId: number;
  salesManagerName: string;
  salesManagerLeague: number;
  salesManagerTeamName: string;
  slotUrl: string;
  slotTier: string;
  bookedOpportunityId: string;
}

export interface CaseInfo {
  processingType: string;
  globalCustomerId: string;
  opportunityId: string;
  caseId: string;
  referrer: string;
  contactId: string;
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  zipcode: string;
  bookingStatus: string;
  leadTier: LeadTier;
  paymentModel: string;
  productType: string;
  caseComments: CaseComments[];
  callCounter: number;
  notReachedCounter: number;
  doNotCallBefore: Date | null;
  salesScript: string;
  salesObjections: SalesObjection[];
  salesChannel: SalesChannel | null;
  expireAt: Date | null;
  createdDate: Date | null;
}

export const defaultCaseInfo: CaseInfo = {
  processingType: '',
  globalCustomerId: '',
  opportunityId: '',
  caseId: '',
  referrer: '',
  contactId: '',
  salutation: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  zipcode: '',
  bookingStatus: '',
  leadTier: LeadTier.None,
  productType: '',
  paymentModel: '',
  caseComments: [],
  callCounter: 0,
  notReachedCounter: 0,
  doNotCallBefore: null,
  salesScript: '',
  salesObjections: [],
  salesChannel: null,
  expireAt: null,
  createdDate: null,
};

export interface CaseComments {
  body: string;
  createdBy: string;
  createdOn: Date;
  isCustomerLostReason: boolean;
  looseReasons: string;
}

export interface OpportunityComplete {
  id: string;
  globalCustomerId: string;
  caseId: string;
  opportunityComments: string;
  doNotCallBefore: Date | null;
  customerLostReasons: string[] | null;
  customerLostDescription: string | null;
  caseStatus: CaseStatus;
  shareContactData?: boolean | null;
}

export interface CaseAssignment {
  caseId: string;
  userEmail: string;
  expireAt: Date | null;
}

export interface TotalOpportunities {
  count: number;
  opportunities: CaseInfo[];
}

export enum CaseStatus {
  None = 0,
  Booked = 1,
  ReachedButNotBooked = 2,
  NotReached = 3,
  CustomerLost = 4,
  WrongNumber = 5,
}

export interface SalesScripts {
  scripts: SalesScript[];
  objections: SalesObjection[];
  salesChannel: SalesChannel;
}

export interface SalesScript {
  id: number;
  bookingStatus: string;
  salesScript: string;
}

export interface SalesObjection {
  id: number;
  sortOrder: number;
  objection: string;
  answer: string;
  isDeleted: boolean;
}

export interface BulkUploadCase {
  cases: string[];
  overrides: {
    callCount: string | undefined;
  };
}

export enum SalesChannel {
  DigitalSales = 'DigitalSales',
  FieldSales = 'FieldSales',
  CrmSales = 'CrmSales',
  Revision = 'Revision',
}
