//import { faker } from '@faker-js/faker';
import { RankingData, RankData, LeaderboardDto } from '../dto/reporting-models';

const reportBaseUrl = (process as any).env.REACT_APP_REPORT_API_ROOT_URL;

export const reportingService = {
  async getLeaderboardDataAsync(accessToken: string): Promise<LeaderboardDto> {
    const url = `${reportBaseUrl}/api/v1/leaderboard`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },
};
