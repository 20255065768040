import './admin-page.css';
import './admin-page.css';

import { Header } from "../header/header";
import { Menu } from "antd";
import { useState } from "react";
import AdminTranscription from "./components/admin-transcription";
import type { MenuProps } from "antd";
import AdminSnoozeConfig from "./components/admin-snooze-config";
import { ScriptEditorPage } from "../script-editor/script-editor";
import AdminTopBookers from './components/admin-top-bookers';

type MenuItem = Required<MenuProps>['items'][number];

export const AdminPage = () => {
  const [selectedKey, setSelectedKey] = useState<string>('1');

  const items: MenuItem[] = [
    {
      key: 1,
      label: "Snooze Config"
    },
    {
      key: 2,
      label: "Scripts"
    },
    {
      key: 3,
      label: "Transcription"
    },
    {
      key: 4,
      label: "Top Bookers"
    }
  ];

  return (
    <>
      <Header />
      <div className="flex-admin-container">
        <Menu
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{ width: '11%' }}
          onSelect={(item) => {
            setSelectedKey(item.key.toString());
          }}
          items={items}
        ></Menu>
        {selectedKey === "1" && <AdminSnoozeConfig />}
        {selectedKey === "2" && <ScriptEditorPage />}
        {selectedKey === "3" && <AdminTranscription />}
        {selectedKey === "4" && <AdminTopBookers />}
      </div>
    </>
  );
};
